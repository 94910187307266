.product{
    width: 12rem;
    height: 8rem;
    background-color: var(--white,white);
    position: relative;
    overflow: hidden;
    padding: 1rem;
    display: flex;
    border-radius: 1rem;
}

.product>img{
    top: 3rem;
    width: 6rem;
    height: 11rem;
}

