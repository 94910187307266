.testimonial {
  display: flex;
  flex-direction: column;
  position: relative;
  background: var(--white, white);
  border-radius: 0.5rem;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  gap: 1rem;
  box-shadow: 0rem 1rem 3rem -50px var(--grey, #7d7d7d);
}

.testimonial > img {
  position: absolute !important;
  width: 3rem !important;
  top: -1.5rem !important;
  left: 45%;
}

.testimonial > span:nth-of-type(1) {
  align-items: center;
  font-size: 0.8rem;
  letter-spacing: 1px;
  margin-top: 2rem;
}

.testimonial > span:nth-of-type(2) {
  font-weight: 500;
}

.testimonial > hr {
  height: 1px;
  width: 80%;
  background-color: rgb(198, 198, 198);
  border: none;
}
